#appDiv {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#navAndBody {
  flex: 1 0 auto;
}

#footer {
  flex-shrink: 0;
}

#archiveList ul {
  margin-bottom: 0;
  padding: 0;
  list-style-type: none;
}

#archiveList a {
  text-decoration: none;
  color: black;
  width: 100%;
  display: block;
}

@media screen and (min-width: 576px) {
  #floating-parking-btn {
    display: flex;
  }
}

.modal-dialog.modal-dialog-centered {
  background: transparent;
}
