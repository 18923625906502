.faqDesktopImg {
  display: block;
}

.faqMobileImg {
  display: none;
}

@media screen and (max-width: 991px) {
  .faqDesktopImg {
    display: none;
  }

  .faqMobileImg {
    display: block;
  }
}
