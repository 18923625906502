.carousel-indicators {
  background-color: transparent;
}

#divUnderAbsoluteDiv {
  margin-top: 6em;
}

#quoteIconDiv {
  font-size: 10vw;
}

.desktopImgCarouselHeader {
  display: block;
  font-size: 5vw;
}

.mobileImgCarouselHeader {
  display: none;
}

.desktopImgCarouselCaption {
  display: block;
  font-size: 2vw;
}

.mobileImgCarouselCaption {
  display: none;
}

.homeMobileImg {
  display: none;
}

.homeDesktopImg {
  display: inline-block;
}

.desktopCaption {
  font-size: 2vw;
  text-decoration: none;
}

.captionLink:hover {
  text-decoration: underline;
}

#desktopMeetTheTeam {
  min-height: 500px;
  border-radius: 15px;
}

#mobileMeetTheTeam {
  display: none;
}

#mobileMeetTheTeamHr {
  display: none;
}

@media screen and (max-width: 1600px) {
  #desktopMeetTheTeam {
    min-height: 500px;
    border-style: none;
    border-radius: 0px;
  }
}

@media screen and (max-width: 1399px) {
  #desktopMeetTheTeam {
    display: none;
  }

  #mobileMeetTheTeam {
    display: block;
    background-repeat: no-repeat;
  }

  #mobileMeetTheTeamHr {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  #divUnderAbsoluteDiv {
    margin-top: 6.5em;
  }

  #quoteIconDiv {
    font-size: 15vh;
  }

  #quoteTextDiv {
    text-align: center;
  }

  .desktopCaption {
    font-size: 3vh;
    text-decoration: none;
  }

  .desktopImgCarouselHeader {
    display: none;
  }

  .homeMobileImg {
    display: inline-block;
  }

  .homeDesktopImg {
    display: none;
  }

  .mobileImgCarouselHeader {
    display: block;
    font-size: 4vh;
    font-weight: 600;
  }

  .desktopImgCarouselCaption {
    display: none;
  }

  .mobileImgCarouselCaption {
    display: block;
    font-size: 2vh;
    font-weight: 600;
  }

  @media screen and (orientation: landscape) {
    #quoteIconDiv {
      font-size: 15vw;
    }

    .mobileImgCarouselHeader {
      display: block;
      font-size: 4vw;
      font-weight: 600;
    }

    .mobileImgCarouselCaption {
      display: block;
      font-size: 2vw;
      font-weight: 600;
    }

    .desktopCaption {
      font-size: 2vw;
      text-decoration: none;
    }
  }
}
