body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  background-color: floralwhite;
  font: 1.2em "News Gothic BT Roman", sans-serif;
}

.siteHeader {
  font-size: 3vw;
  text-decoration: none;
}

.desktopParagraph {
  font-size: 1.5vw;
  text-decoration: none;
}

.desktopParagraphLarge {
  font-size: 2vw;
  text-decoration: none;
}

.desktopParagraphSmall {
  font-size: 1.25vw;
  text-decoration: none;
}

.desktopImg {
  display: block;
  border-radius: 15px;
}

.mobileImg {
  display: none;
}

.desktopParagraphLink {
  text-decoration: underline;
}

@media screen and (max-width: 1600px) {
  .desktopImg {
    border-style: none;
    border-radius: 0px;
  }
}

@media screen and (max-width: 991px) {
  .desktopImg {
    display: none;
  }

  .mobileImg {
    display: block;
  }

  .siteHeader {
    font-size: 5vh;
    text-decoration: none;
    text-align: center;
  }

  .desktopParagraph {
    font-size: 2.5vh;
    text-decoration: none;
  }

  .desktopParagraphLarge {
    font-size: 3vh;
    text-decoration: none;
  }

  .desktopParagraphSmall {
    font-size: 2.25vh;
    text-decoration: none;
  }

  @media screen and (orientation: landscape) {
    .siteHeader {
      font-size: 3vw;
    }

    .desktopParagraph {
      font-size: 1.5vw;
      text-decoration: none;
    }

    .desktopParagraphLarge {
      font-size: 2vw;
      text-decoration: none;
    }

    .desktopParagraphSmall {
      font-size: 1.25vw;
      text-decoration: none;
    }
  }
}
