.desktopImgs {
  display: block;
}

.mobileImgs {
  display: none;
}

.desktopContactContent {
  display: block;
}

.mobileContactContent {
  display: none;
}

@media screen and (max-width: 991px) {
  .desktopImgs {
    display: none;
  }

  .mobileImgs {
    display: block;
  }

  .desktopContactContent {
    display: none;
  }

  .mobileContactContent {
    display: block;
  }
}
