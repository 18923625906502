#footerLogo {
  display: block;
}

#footerLogoMobile {
  display: none;
}

.footerSeparator {
  display: none;
}

.footerContent {
  font-size: 1.25vw !important;
}

/* .footerCol {
  text-align: end;
} */

.footerIcon {
  font-size: 2.75vh;
}

@media screen and (max-width: 991px) {
  #footerSocials {
    margin-bottom: 3.5em;
  }

  #footerLogo {
    display: none;
  }

  #footerLogoMobile {
    display: block;
  }

  .footerSeparator {
    display: block;
  }

  .footerContent {
    font-size: calc(16px + 1.25vw) !important;
  }

  /* .footerCol {
    text-align: start;
  } */

  .footerIcon {
    font-size: 2.75vh;
  }
}
