.desktopProductImg {
  display: block;
}

.mobileProductImg {
  display: none;
}

@media screen and (max-width: 991px) {
  .desktopProductImg {
    display: none;
  }

  .mobileProductImg {
    display: block;
  }
}
