.desktopImgAboutUs {
  display: block;
}

.mobileImgAboutUs {
  display: none;
}

@media screen and (max-width: 991px) {
  .desktopImgAboutUs {
    display: none;
  }

  .mobileImgAboutUs {
    display: block;
  }
}
