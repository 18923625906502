.responsiveIcon {
  font-size: 4vh;
}

.responsiveDropdownIcon {
  font-size: 2.5vh;
}

#offcanvasNavBarHr {
  display: none;
}

.navBarLink {
  font-size: 1.25vw !important;
}

.activePageLink {
  font-size: 1.25vw !important;
  color: #d3c464 !important;
}

.mobileNavLink {
  display: none;
}

.desktopNavLink {
  display: inline-block;
}

.desktopLogoBtn {
  min-width: 225px;
  max-width: 275px;
}

@media screen and (min-width: 992px) {
  .navBarLink {
    font-size: 20px !important;
  }

  .activePageLink {
    font-size: 20px !important;
    color: #d3c464 !important;
  }
}

@media screen and (max-width: 991px) {
  #offcanvasNavBarHr {
    display: block;
  }

  .mobileNavLink {
    display: inline-block !important;
  }

  .desktopNavLink {
    display: none;
  }

  .navBarLink {
    font-size: calc(20px + 1.25vw) !important;
  }

  .activePageLink {
    font-size: calc(20px + 1.25vw) !important;
    color: #d3c464 !important;
  }
}

@media screen and (orientation: landscape) {
  .responsiveIcon {
    font-size: 4vw;
  }

  .responsiveDropdownIcon {
    font-size: 2.5vw;
  }
}
